import "@hotwired/turbo-rails"

// import "./controllers"

// document.addEventListener('aos:in', ({ detail }) => {
//   console.log('animated in', detail);
// });
//

import AOS from 'aos'
AOS.init()

// Get all sections that have an ID defined
const sections = document.querySelectorAll("section[id]")
// console.log(sections)

// Add an event listener listening for scroll
window.addEventListener("scroll", navHighlighter)

function navHighlighter () {

  // Get current scroll position
  let scrollY = window.scrollY

  // Now we loop through sections to get height, top and ID values for each
  sections.forEach(current => {
    const sectionHeight = current.offsetHeight

    // Original:
    // const sectionTop = current.offsetTop - 50;
    //
    // Alex Turnwall's update:
    // Updated original line (above) to use getBoundingClientRect instead of offsetTop, based on:
    // https://plainjs.com/javascript/styles/get-the-position-of-an-element-relative-to-the-document-24/
    // https://newbedev.com/difference-between-getboundingclientrect-top-and-offsettop
    // This allows the use of sections inside a relative parent, which I'm not using here, but needed for a project
    //
    const sectionTop = (current.getBoundingClientRect().top + window.scrollY) - 50
    const sectionId = current.getAttribute("id")

    /*
    - If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
    - To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
    */
    if (
      scrollY > sectionTop &&
      scrollY <= sectionTop + sectionHeight
    ) {
      // console.log('on navi id: -', sectionId, '-' )
      document.querySelector("#top-navi a[href*=" + sectionId + "]")
        .classList
        .add("active")
    } else {
      // console.log('off navi id: -', sectionId, '-' )
      try {
        document.querySelector("#top-navi a[href*=" + sectionId + "]")
          .classList
          .remove("active")
      } catch (e) { }
    }
  })
}

document.addEventListener("DOMContentLoaded", () => {
  var videos = [].slice.call(document.querySelectorAll("video.lazy"))

  if ("IntersectionObserver" in window) {
    var videoObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (video) {
        if (video.isIntersecting) {
          // video.poster = video.dataset.poster
          for (var source in video.target.children) {
            var videoSource = video.target.children[ source ]
            if (
              typeof videoSource.tagName === "string" &&
              videoSource.tagName === "SOURCE"
            ) {
              videoSource.src = videoSource.dataset.src
            }
          }

          video.target.load()
          video.target.classList.remove("lazy")
          videoObserver.unobserve(video.target)
        }
      })
    })

    videos.forEach(function (video) {
      videoObserver.observe(video)
    })
  }
})
